@import "variables";
@import "mixins/breakpoints";

.moments-comparison-chart__container {
  padding: 10px;
  background-color: $moments-background-color;

  .moments-comparison-chart__supported svg {
    padding: 1px;
  }

  svg {
    width: 1.2em;
    height: 1.2em;
  }

  .moments-comparison-chart__table-scroll {
    display: flex;
    justify-content: center;
    color: $moments-contrast-color;

    @include media-breakpoint-down(sm) {
      background-color: $moments-background-color;
      position: relative;
      max-width: 100%;
      margin: auto;
      overflow: hidden;
    }

    @include media-breakpoint-down(sm) {
      .moments-comparison-chart__table-wrap {
        width: 100%;
        overflow: auto;
        scrollbar-width: thin;

        /* width */
        &::-webkit-scrollbar {
          width: 10px;
          height: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: $moments-background-color-lighter;
          border-radius: 4px;
          height: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $moments-accent-color;
          border-radius: 4px;
          height: 4px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }

      .comparison-chart__table-clone {
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;

        th,
        td {
          visibility: hidden;
          border-color: transparent;
        }

        tbody th {
          visibility: visible;
        }

        .comparison-chart__fixed-side {
          background: $moments-background-color;
          visibility: visible;
        }

        thead,
        tfoot {
          background: transparent;
        }
      }
    }

    .js-moments-comparison-chart__table {
      text-align: center;
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;

      @include media-breakpoint-down(sm) {
        padding-bottom: 10px;
        width: 200%;
        width: calc(200% + 4px);
        margin: 0 -4px;
        border-collapse: separate;
        border-spacing: 4px 0;
        table-layout: fixed;

        td,
        th {
          //white-space: nowrap;
          width: 50%;
        }
      }

      td {
        @include media-breakpoint-up(md) {
          line-height: 1.5;
        }
      }

      thead th {
        padding: 15px 0;

        &:first-child {
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }

        &:nth-child(2) {
          @include media-breakpoint-down(sm) {
            border-top: 4px solid $moments-accent-color;
            border-left: 4px solid $moments-accent-color;
            border-right: 4px solid $moments-accent-color;
            border-radius: 4px 4px 0 0;
            background-color: $moments-accent-color-faded;
          }
        }

        &:nth-child(n + 3) {
          @include media-breakpoint-down(sm) {
            border-top: 4px solid $moments-background-color-gray;
            border-left: 4px solid $moments-background-color-gray;
            border-right: 4px solid $moments-background-color-gray;
            border-radius: 4px 4px 0 0;
            background-color: $moments-background-color-gray;
          }
        }

        span {
          display: block;

          &.moments-th-title-company {
            text-transform: uppercase;

            &.moments-th-title-brand {
              // fix for contrast
              color: #5e75ff;
            }
          }
        }
      }

      tbody>tr {
        line-height: 5rem;
        border-bottom: 1px solid #232b32;

        &.comparison-chart__mobile-row {
          line-height: 3.5rem;
          display: table-row;

          @include media-breakpoint-up(md) {
            display: none;
          }

          @include media-breakpoint-down(sm) {
            background: $moments-background-color;

            &>td.comparison-chart__fixed-side {
              border-right: none !important;
              background: linear-gradient(to right,
                  $moments-accent-color-faded,
                  $moments-background-color,
                  $moments-background-color-gray ) !important;
            }
          }
        }

        &.remove-bottom-border {
          border-bottom: none;
        }

        &:nth-child(2) {
          td:nth-child(2) {
            @include media-breakpoint-up(md) {
              border-top: 4px solid $moments-accent-color;
              border-radius: 4px 4px 0 0;
              background-color: $moments-accent-color-faded;
            }
          }
        }

        &:last-child {
          td {
            &:nth-child(2) {
              border-bottom: 4px solid $moments-accent-color;
              border-radius: 0 0 4px 4px;
              background-color: $moments-accent-color-faded;
            }

            &:nth-child(n + 3) {
              @include media-breakpoint-down(sm) {
                border-bottom: 4px solid $moments-background-color-gray;
                border-radius: 0 0 4px 4px;
                background-color: $moments-background-color-gray;
              }
            }
          }
        }

        th {
          text-align: left;

          &.comparison-chart__subrow {
            font-weight: 400;
            padding-left: 1.5em;
          }

          @include media-breakpoint-down(sm) {
            display: none;
          }
        }

        td {
          &.comparison-chart__cell__set-opaque {
            .comparison-chart__pill {
              border-radius: 100px;
              display: block;
              margin: 0px 3em 0px 3em;
              background: linear-gradient(to right,
                  rgba(60, 68, 78, 0),
                  #293239,
                  rgba(60, 68, 78, 0)) !important;
            }
          }

          &.comparison-chart__subrow.comparison-chart__cell__set-opaque {
            .comparison-chart__pill {
              border-radius: 100px;
              display: block;
              // margin: 0px 6em 0px 6em;
              background: linear-gradient(to right,
                  rgba(60, 68, 78, 0),
                  #293239,
                  rgba(60, 68, 78, 0)) !important;
            }
          }

          &:nth-child(2) {
            border-left: 4px solid $moments-accent-color;
            border-right: 4px solid $moments-accent-color;
            background-color: $moments-accent-color-faded;

            svg {
              cursor: pointer;
              color: white;
              border: 2px solid $moments-accent-color;
              background: $moments-accent-color;
            }
          }

          &:nth-child(n + 3) {
            @include media-breakpoint-down(sm) {
              border-left: 4px solid $moments-background-color-gray;
              border-right: 4px solid $moments-background-color-gray;
              background-color: $moments-background-color-gray;
            }
          }

          svg {
            cursor: pointer;
            color: $black;
            border-radius: 30px;
            border: 2px solid #f2f4f9;
            background: #f2f4f9;
          }

          .moments-comparison-chart__not-supported svg {
            color: $gray-400;
            background: #212529;
            rotation: 45deg;
            border: 2px solid #212529;
          }
        }
      }
    }

    // CHART TOOLTIPS
    .moments-comparison-chart__table-help {
      display: inline-block;
      width: 14px;
      cursor: pointer;
      color: $moments-accent-color;
      transition: opacity 100ms ease;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

th {
  &.comparison-chart__fixed-side {
    font-size: 1.8rem;
  }

  .moments-th-title-company {
    font-size: 1.4rem;
  }

  .moments-th-title-software {
    font-size: 1.8rem;
  }
}

.comparison-chart__fixed-subtitle {
  display: block;
  color: $moments-hawkes-gray;
  font-size: 1.2rem;
  line-height: 0.2;
  padding-bottom: 20px;
}

td {
  &.comparison-chart__fixed-side {
    &.comparison-chart__subrow {
      line-height: 3.5rem;
    }
  }
}

.comparison-chart__pill {
  text-transform: uppercase;
  white-space: nowrap;
}
